<!-- 收货产品记录 -->
<template>
  <div class="pagePadding home">
    <Form inline class="formMarginBtm20 clearfix">
      <!-- 供应商名称 -->
      <FormItem>
        <span>供应商名称：</span>
        <Select placeholder="请选择" v-model="queryFrom.supplier_id" clearable filterable class="iviewIptWidth250" @on-change="getSupName">
          <Option v-for="(item, index) in supllyList" :value="item.supplier_id" :key="index">{{ item.supplier_name }}</Option>
        </Select>
      </FormItem>
      <FormItem class="marginLeft40">
        <span>产品编号：</span>
        <i-input placeholder="请输入" class="iviewIptWidth250" v-model="queryFrom.product_code"></i-input>
      </FormItem>
      <!-- 产品名称 -->
      <FormItem class="marginLeft40">
        <span>产品名称：</span>
        <Select placeholder="请选择" v-model="queryFrom.product_id" clearable filterable class="iviewIptWidth250" @on-change="getSKUSelect">
          <Option v-for="(item, index) in productSelect" :value="item.product_id" :key="index">{{ item.product_name }}</Option>
        </Select>
      </FormItem>
      <!-- 货号/SKU -->
      <FormItem class="marginLeft40">
        <span>规格/SKU：</span>
        <Select placeholder="请选择" v-model="queryFrom.specification_id_arr" clearable filterable multiple :max-tag-count="queryFrom.specification_id_arr.length == 1 ? 1 : 0" class="iviewIptWidth250">
          <Option v-for="(item, index) in SKUSelect" :value="item.specification_id" :key="index" :label="item.model_name + '-' + item.item_number">
            <!-- {{ item.model_name + '/' + item.item_number }} -->
            <span>{{ item.model_name }}</span>
            <span style="float: right; color: #ccc; margin-right: 20px;">{{ item.item_number }}</span>
          </Option>
        </Select>
      </FormItem>
      <!-- 批号 -->
      <FormItem class="marginLeft40">
        <span>批号：</span>
        <Input placeholder="请输入" v-model="queryFrom.batch_number" clearable class="iviewIptWidth250"> </Input>
      </FormItem>
      <!-- 收货人 -->
      <FormItem>
        <span>收货人：</span>
        <Select placeholder="请选择" v-model="queryFrom.create_by" clearable filterable class="iviewIptWidth250">
          <Option v-for="(item, index) in acceptSelect" :value="item.id" :key="index">{{ item.user_name }}</Option>
        </Select>
      </FormItem>
      <!-- 收货时间 -->
      <FormItem class="marginLeft40">
        <span>收货时间：</span>
        <DatePicker type="date" placeholder="请选择" class="iviewIptWidth250" clearable @on-change="changeTime($event, 1)"></DatePicker>
        <span style="padding: 0 10px;">至</span>
        <DatePicker type="date" placeholder="请选择" class="iviewIptWidth250" v-model="queryFrom.create_time_end" clearable :options="options" @on-change="changeTime($event, 2)"></DatePicker>
      </FormItem>
      <!-- 按钮 -->
      <FormItem class="po-create-number marginRight0" :label-width="20">
        <span class="pageBtn finger btnSure" @click="searchItem">查询</span>
        <span class="longTextBtn finger btnAdd marginLeft20" @click="exportMerge">
          <Spins style="display: inline;" v-if="flx">
            <Icon type="ios-loading" size="18" class="demo-spin-icon-load"></Icon>
          </Spins>
          合并导出</span
        >
        <span class="pageBtn finger btnSure marginLeft20" @click="setupStatus = true">设置</span>
        <span class="pageBtn finger btnSure marginLeft20" @click="printing">打印</span>
      </FormItem>
    </Form>
    <!-- <Table :productList="listColumns" :productData="listData" :pages="pages" @change-page="changePage" totalText="条记录" :total="total">
      <div slot="summary" class="summary">
        总数量：<span class="color389">{{ total_number }}</span> 合计金额：<span class="color389">¥{{ total_amount }}</span>
      </div>
    </Table> -->
    <div class="clearfix tabDiv" ref="tabDiv">
      <Spin fix v-if="tabloading"></Spin>
      <Table :height="tableHeight" :productList="listColumns" :option_page="option_page" :productData="listData" highlight-row border :chaxunFlag="chaxunFlag" :rowClass="rowClassName"></Table>
    </div>
    <div class="summary" v-if="listData && listData.length > 0">
      总数量：<span class="color389 marginRight20">{{ total_number }}</span> 合计金额：<span class="color389">{{ $utils.formatMoney(total_amount, true) }}</span>
    </div>
    <div class="pages" v-if="listData !== null && listData.length > 0 && total">
      <Page v-if="pages && total <= 60" :total="total" :current="pages.page" @on-change="changePage" class="fl" />
      <Page v-if="pages && total > 60" :total="total" :current="pages.page" @on-change="changePage" show-elevator class="fl" />
      <div class="fr totalFont" v-if="total">共计{{ total }}条记录</div>
    </div>
    <!-- <div style="position: relative; border: 1px solid #e8eaec; height: 500px; border-top: none !important;" class="clearfix noData" v-if="listData !== null && listData.length == 0">
        <img style="margin-top: 100px;" src="@/assets/images/tableNoData.png" />
      </div> -->
    <!-- 动态表头弹窗 -->
    <table-setup v-if="setupStatus" :pageList="titleList" :option_page="option_page" @cancleBtn="setupStatus = false" @sureBrn="sureSetup"></table-setup>
    <!-- 新增 -->
    <Modal v-model="status" title="请选择模版" cancel-text @on-cancel="status = false" width="568">
      <div class="clearfix paddingBot40px">
        <div class="item">
          <span class="itemTitle">模版类型：</span>
          <Select placeholder="请选择" v-model="template_type" filterable class="iviewIptWidth307" @on-change="typeChange">
            <Option v-for="(item, index) in TemplateList" :value="item.typeID" :key="index">{{ item.typeName }}</Option>
          </Select>
        </div>
        <div class="item">
          <span class="itemTitle">模版名称：</span>
          <Select placeholder="请选择" v-model="template_id" filterable class="iviewIptWidth307" :disabled="!template_type">
            <Option v-for="(item, index) in typeList" :value="item.template_id" :key="index">{{ item.template_name }}</Option>
          </Select>
        </div>
        <!-- <div class="item">
          <span class="itemTitle">每页行数：</span>
          <InputNumber  :min="0" :precision="0" v-model="paging" :active-change="false"  class="iviewIptWidth307"></InputNumber>
        </div> -->
      </div>
      <div slot="footer">
        <span class="pageBtn finger btnSure" @click="surePrint">确定</span>
      </div>
    </Modal>
  </div>
</template>

<script>
import Table from '@/components/table'
import TableSetup from '@/components/tableSetup'
import { downFile } from '../../../service/http'
export default {
  name: 'recordProductsReceivedConsign',
  components: {
    Table,
    TableSetup,
  },
  data() {
    return {
      chaxunFlag: true,
      tabloading: false,
      tableHeight: 0,
      supllyList: [],
      TemplateList: [
        { typeName: '采购单', typeID: 1 },
        { typeName: '收货验收单', typeID: 2 },
        { typeName: '入库单', typeID: 3 },
        { typeName: '销售单', typeID: 4 },
        { typeName: '拣货单', typeID: 5 },
        { typeName: '出库单', typeID: 6 },
        { typeName: '采购记录', typeID: 7 },
        { typeName: '收货验收记录', typeID: 8 },
        { typeName: '入库记录', typeID: 9 },
        { typeName: '销售记录', typeID: 10 },
        { typeName: '拣货记录', typeID: 11 },
        { typeName: '出库记录', typeID: 12 },
        { typeName: '调拨单', typeID: 13 },
      ],
      template_id: '',
      template_type: 8,
      typeList: [],
      status: false,
      titleList: [],
      option_page: '03',
      // 动态表头弹窗
      setupStatus: false,
      // 表格数据
      listData: [],
      // 表格头部标题
      listColumns: [
        {
          title: '序号',
          type: 'index',
          align: 'center',
          width: 75,
        },
        {
          title: '产品名称',
          key: 'product_name',
          align: 'center',
          minWidth: 260,
        },
        {
          title: '注册证号/备案凭证号',
          key: 'licence_code',
          align: 'center',
          width: 220,
        },
        {
          title: '规格型号',
          key: 'model_name',
          align: 'center',
          width: 136,
        },
        {
          title: '货号/SKU',
          key: 'item_number',
          align: 'center',
          width: 136,
        },
        {
          title: '条形码',
          key: 'bar_code',
          align: 'center',
          width: 200,
        },
        {
          title: '批号',
          key: 'batch_number',
          align: 'center',
          width: 200,
        },
        {
          title: '有效期',
          key: 'valid_period',
          align: 'center',
          width: 136,
        },
        {
          title: '厂家',
          key: 'factory_name',
          align: 'center',
          minWidth: 260,
        },
        {
          title: '数量',
          key: 'quantity',
          align: 'center',
          width: 136,
        },
        {
          title: '收货人',
          key: 'user_name',
          align: 'center',
          width: 260,
        },
        {
          title: '收货时间',
          key: 'create_time',
          align: 'center',
          width: 136,
        },
      ],
      flx: false,
      // 表单拉下选项
      thirdList: [],
      // 查询参数
      queryFrom: {
        product_code: '',
        product_id: '',
        specification_id_arr: [],
        batch_number: '', // 批号
        create_by: '', // 创建人id
        create_time_start: '',
        create_time_end: '',
      },
      pages: {
        page: 1,
        rows: 10,
      },
      total: 0, // 项目条数
      total_amount: 0, // 产品总金额
      total_number: 0, // 产品总数量
      // 时间设置
      options: {},
      // 规格选择禁用
      productDisable: true, // 产品禁用
      productSelect: [], // 产品下拉
      SKUSelect: [], // 规格下拉
      acceptSelect: [], // 收货人下拉
      paging: null,
    }
  },
  methods: {
    printing() {
      this.status = true
      this.getTemplate()
    },
    // 获取最近的模板
    getTemplate() {
      // 获取模板名称
      this.$http.get(this.$apiConsign.templateInfo, { template_type: this.template_type }, false).then(res => {
        this.typeList = res.data
      })
      // 获取模板记录
      this.$http.get(this.$apiConsign.recentTemplate, { template_type: this.template_type }, true).then(res => {
        if (res.data) {
          this.template_id = res.data.template_id
        }
      })
    },
    getSupllyList() {
      this.$http.get(this.$apiConsign.supplierSelect).then(res => {
        this.supllyList = res.data
      })
    },
    surePrint() {
      let api_info = `${this.$apiConsign.acceptProductManage.url}?`
      console.log(this.queryFrom)
      for (let objName in this.queryFrom) {
        console.log(objName)
        if (this.queryFrom[objName] && objName != 'specification_id_arr') {
          api_info = api_info + `${objName}=${this.queryFrom[objName]}&`
        }
      }
      api_info = this.queryFrom.specification_id_arr.length > 0 ? api_info + `specification_id_str=${this.queryFrom.specification_id_arr.join(',')}` : api_info
      if (!this.template_type) {
        this.$Message.warning('请选择模版类型')
        return
      }
      if (!this.template_id) {
        this.$Message.warning('请选择模版名称')
        return
      }
      // if (window.require('electron')) {
      //   let ipcRenderer = window.require('electron').ipcRenderer
      //   // ipcRenderer.on('ping', (e) => { // 接收webview事件
      //   //   console.log(e)
      //   //   alert(123)
      //   // }
      //   ipcRenderer.sendToHost('testwebview', { type: 'print', url: `${this.$apiConsign.templatePrint.url}?template_id=${this.template_id}&api_info=${api_info}`, token: 'Bearer ' + localStorage.getItem('adminohoqicbi==') }) // 向渲染进程发送事件
      //   this.status = false
      //   setTimeout(() => {
      //     this.$Message.success('导出成功,正在打开...')
      //   }, 1000)
      // } else {
      this.$http.downFile(this.$apiConsign.templatePrint, { template_id: this.template_id, api_info: api_info, paging: this.paging }, true).then(res => {
        this.status = false

        this.$Message.success('导出成功')
        let blob = new Blob([res.data], { type: 'application/x-xlsx' })
        const aLink = document.createElement('a')
        aLink.href = URL.createObjectURL(blob)
        aLink.download = '收货产品记录.xlsx'
        aLink.click()
      })
      // }
    },
    // 发送请求获取 模版名称
    typeChange(e) {
      if (!e) return
      this.$http.get(this.$apiConsign.templateInfo, { template_type: e }, false).then(res => {
        this.typeList = res.data
      })
    },
    sureSetup(val) {
      this.queryOption()
    },
    // 获取表格表头
    queryOption() {
      this.tabloading = true
      this.$http
        .get(this.$apiConsign.systemOptionItem, { option_page: this.option_page })
        .then(res => {
          this.titleList = res.data.enable
          localStorage.setItem('adminTtitleList', JSON.stringify(res.data.list))
          for (let i = 0; i < this.titleList.length; i++) {
            if (this.titleList[i].show) {
              this.titleList[i].key = this.titleList[i].data_col
              this.titleList[i].width = this.titleList[i].column_width
              this.titleList[i].align = 'center'
              this.titleList[i].resizable = true
            }
          }
          this.listColumns = []
          this.listColumns.unshift({
            title: '序号',
            type: 'index',
            align: 'center',
            width: 60,
          })
          this.listColumns = [...this.listColumns, ...this.titleList]
          this.listColumns = JSON.parse(JSON.stringify(this.listColumns))
        })
        .then(() => {
          this.tabloading = false
          // this.getTableList(this.pages, {})
        })
    },
    // 合并导出
    exportMerge() {
      let data = {
        product_id: this.queryFrom.product_id,
        specification_id_str: this.queryFrom.specification_id_arr,
        batch_number: this.queryFrom.batch_number,
        create_by: this.queryFrom.create_by,
        create_time_start: this.queryFrom.create_time_start,
        create_time_end: this.queryFrom.create_time_end,
        product_code: this.queryFrom.product_code,
        title: '1',
      }
      this.flx = true
      this.$http.downFile(this.$apiConsign.acceptanceLineExport, data).then(res => {
        this.flx = false
        let blob = new Blob([res.data], { type: 'application/x-xlsx' })
        const aLink = document.createElement('a')
        aLink.href = URL.createObjectURL(blob)
        aLink.download = '收货产品记录导出表.xlsx'
        aLink.click()
      })
    },
    // 反冲成功的行标红
    rowClassName(row, index) {
      if (row.related_status == 3) return 'redRow'
      return ''
    },
    /* 获取表格数据 */
    getTableList(pages, searchItem) {
      this.tabloading = true
      let obj = {}
      Object.assign(obj, pages, searchItem)
      this.$http.get(this.$apiConsign.acceptProductManage, obj).then(res => {
        this.tabloading = false
        if (res.status) {
          this.listData = res.data
          this.total = res.total
          this.total_amount = res.total_amount
          this.total_number = res.total_number
          // 处理动态表头页面列表数据
          this.listData = this.$utils.dynamicHeaderList(this, this.listData)
          if (this.listData.length > 0) {
            this.$nextTick(() => {
              this.tableHeight = this.$refs.tabDiv.offsetHeight + ''
            })
          } else {
            this.$nextTick(() => {
              this.tableHeight = '0'
            })
          }
        }
      })
    },
    // 搜索
    searchItem() {
      this.chaxunFlag = false
      let create_time_start = this.queryFrom.create_time_start
      let create_time_end = this.queryFrom.create_time_end
      let product_id = this.queryFrom.product_id
      let specification_id_arr = this.queryFrom.specification_id_arr
      let specification_id_str = specification_id_arr.join(',')
      let batch_number = this.queryFrom.batch_number
      let create_by = this.queryFrom.create_by
      let supplier_id = this.queryFrom.supplier_id
      let product_code = this.queryFrom.product_code
      let obj = {
        product_id,
        specification_id_str,
        batch_number,
        create_time_start,
        create_time_end,
        create_by,
        supplier_id,
        product_code,
      }
      this.searchObj = obj
      this.pages = { page: 1, rows: 10 }
      this.getTableList(this.pages, this.searchObj)
    },
    /* 分页方法 */
    changePage(e) {
      this.pages.page = e
      this.getTableList(this.pages, this.searchObj)
    },
    /* 获取下拉列表 */
    getSelect() {
      // 获取产品名称下拉
      this.$http.get(this.$apiConsign.acceptanceProductSelect, {}).then(res => {
        if (res.status) {
          this.productSelect = res.data
        }
      })
      // 获取收获人下拉
      let user_type = '3'
      this.$http.get(this.$apiConsign.acceptPerson, { user_type }).then(res => {
        if (res.status) {
          this.acceptSelect = res.data
        }
      })
    },
    jude() {
      if (this.queryFrom.product_id) {
        // this.productDisable = false
        let data = {
          // supplier_id: this.queryFrom.supplier_id,
          product_id: this.queryFrom.product_id,
        }
        this.$http.get(this.$apiConsign.acceptanceCodeSelect, data).then(res => {
          this.SKUSelect = res.data
        })
      } else {
        this.$http.get(this.$apiConsign.acceptanceCodeSelect, {}).then(res => {
          this.SKUSelect = res.data
        })
      }
    },
    getSupName(val) {
      // this.jude()
    },
    // 获取产品规格下拉
    getSKUSelect(val) {
      if (val) {
        this.queryFrom.specification_id_arr = []
        this.jude()
        // let search_type = '1'
        // let obj = { product_id, search_type }
        // this.productDisable = false
        // this.$http.get(this.$apiConsign.productShow, obj).then(res => {
        //   if (res.status) {
        //     this.SKUSelect = res.data
        //   }
        // })
      } else {
        this.jude()
        // this.SKUSelect = []
        // this.queryFrom.specification_id_arr = []
        // this.queryFrom.product_id = ''
        // this.productDisable = true
      }
    },
    // 时间改变
    changeTime(e, num) {
      if (num == 1) {
        let that = this
        that.queryFrom.create_time_start = e
        that.queryFrom.create_time_end = ''
        that.options = {
          disabledDate(date) {
            return date && date.valueOf() < new Date(that.queryFrom.create_time_start)
          },
        }
      } else {
        this.queryFrom.create_time_end = e
      }
    },
  },
  created() {
    this.getSupllyList()
    this.queryOption()
    this.getSelect()
    this.jude()
  },
  mounted() {
    // if(window.require('electron')) {
    //   let ipcRenderer = window.require('electron').ipcRenderer
    //
    //   ipcRenderer.on('ping', (e) => { // 接收webview事件
    //     console.log(e)
    //     alert(123)
    //   })
    //   console.log( 'ERP网页发送事件testwebview')
    //   ipcRenderer.sendToHost('testwebview','123') // 向渲染进程发送事件
    // }
  },
}
</script>

<style scoped lang="less">
.home {
  display: flex;
  flex-direction: column;
}
// 表格
.tabDiv {
  flex: 1;
  overflow-y: auto;
  height: 100%;
  .totalFont {
    font-size: 14px;
  }
  .pages {
    margin-top: 10px;
    overflow: hidden;
    line-height: 32px;
  }
}
.paddingBot40px {
  padding-bottom: 40px;
}
.item {
  display: flex;
  align-items: center;
  justify-items: center;
  margin-bottom: 20px;
  .itemTitle {
    width: 100px;
    color: #000;
    text-align: justify;
    height: 32px;
    line-height: 32px;
  }
  .itemTitle::after {
    content: '';
    display: inline-block;
    padding-left: 100%;
  }
}
</style>
